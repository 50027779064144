const getPermissionsFromRoleArray = (roles: any) => (
  [...(new Set(roles.reduce((accumulator: any, curentRole: any) => {
    accumulator.push(...curentRole.permissions);
    return accumulator;
  }, [])))]
);

// combines class level permissions into a single list
export const getPermissions = (roles: any) => {
  if (Array.isArray(roles)) {
    return getPermissionsFromRoleArray(roles);
  }
  const result: any = Object.values(roles).reduce((accumulator: any, currentRole: any) => {
    accumulator.push(...currentRole.perms.filter((aPerm: any) => !aPerm.endsWith('_I')));
    return accumulator;
  }, []);
  return [...(new Set(result))];
};

export const getRefreshTokenFrequency = (auth: any) => {
  const refreshValues = Object.values(auth.getRoles()).filter((each: any) => Number.isInteger(each.refresh))
    .map((each: any) => each.refresh);
  return refreshValues.length ? Math.min(...refreshValues) : undefined;
};

export const decodePayload = (jwtToken: any) => {
  const jwtPayload = jwtToken.split('.')[1];
  try {
    return JSON.parse(atob(jwtPayload));
  } catch (err) {
    return {};
  }
};

export const callFetchAPIWithHeaders = async (url: string, myHeaders: any, raw: any) => {
  try {
    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (response.status === 502) {
      return { ERROR: response.statusText };
    } else if (response.status !== 200) {
      return { ERROR: data };
    } else {
      return { DATA: data };
    }
  } catch (err) {
    return { ERROR: err };
  }
};

export const vitallyPost = async (vitallyData: any, eventType: string) => {
  const vitallyUrl = 'https://tailos.api.vitally.io/analytics/v1/track';
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Basic ${process.env.VITALLY_API_TOKEN}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    userId: vitallyData.userName,
    accountId: vitallyData.propertyId,
    event: eventType,
    properties: {
      first_name: vitallyData.firstName,
      last_name: vitallyData.lastName,
      email: vitallyData.email,
    },
    timestamp: new Date().toISOString(),
  });
  const apiResponse = await callFetchAPIWithHeaders(vitallyUrl, myHeaders, raw);
  if (apiResponse.ERROR) {
    console.log('Vitally API Error', apiResponse.ERROR);
  } else {
    console.log('Vitally API Response', apiResponse.DATA);
  }
};

export const logLogo = () => console.log(`
        ████╗
         ██╔╝
         ██║
███████████████████╗
██╔══════════════██║
██║  ██╗    ██╗  ██║
██║  ╚═╝    ╚═╝  ██║
████╗          ████║
╚═══╝          ╚═══╝
`);
