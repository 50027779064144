import gql from 'graphql-tag';

export default gql`
  query getProperties {
    properties {
      id
      name
      timeZone {
          value
          label
          name
          offset
          abbreviation
      }
    }
  }
`;
