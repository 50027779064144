import { initializeFaro, withFaroRouterInstrumentation } from '@grafana/faro-react';
import { SecureRoute } from '@tailosinc/bender-auth-react-cognito-hook';
import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import AuthPage from '../../components/AuthPage';
import ErrorComponent from '../../components/ErrorComponent';
import RenderWrapper from './render-wrapper';

if (process.env.FARO_ENABLED === 'true') {
  initializeFaro({
    url: process.env.FARO_ENDPOINT,
    app: {
      name: process.env.FARO_APP_NAME,
      version: '1.0.0',
      environment: process.env.NODE_ENV,
    },
  });
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <SecureRoute>
        {RenderWrapper}
      </SecureRoute>
    ),
    children: [
      {
        path: '/auth',
        element: <AuthPage />,
      },
      {
        path: '/page-not-found',
        element: <ErrorComponent statusCode={404} baseUrl={window.location.origin} />,
      },
      {
        path: '/runs',
      },
      {
        path: '/robots',
      },
      {
        path: '/settings',
        children: [
          {
            path: '/settings/profile',
          },
          {
            path: '/settings/notifications',
          },
          {
            path: '/settings/api-tokens',
          },
          {
            path: '/settings/security',
          },
          {
            path: '/settings/assign-robots',
          },
          {
            path: '/settings/team-members',
          },
          {
            path: '/settings/team-notifications',
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/page-not-found" replace />,
  },
]);

const faroRouter = process.env.FARO_ENABLED === 'true' ? withFaroRouterInstrumentation(router) : router;

const Root = () => (
  <RouterProvider router={faroRouter} />
);

export default Root;
